import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { ServerService } from './../services/server.service';
import { environment } from '../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { CompanyIdHeaderService } from './../services/company-id-header.service';

@Injectable({
  providedIn: 'root',
})
class TokenGuard {
  constructor(
    private router: Router,
    private http: HttpClient,
    private serverService: ServerService,
    private companyIdService: CompanyIdHeaderService
  ) {
    this.tokenFromUrl = '';
  }

  tokenFromUrl: string | null;
  companyId: string | null;

  /**
   * Validar token de sesión
   * @param token
   * @returns
   */
  private validateAndRedirect(token: string): Observable<boolean> {
    const companyId = this.companyIdService.getCompanyId();
    // Modificamos cabecera para enviar también company_id
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      company_id: companyId || '',
    });

    return this.http
      .get<boolean>(this.serverService.apiUrl + `/api/check-token`, { headers })
      .pipe(
        tap((response) => {
          if (response) {
            sessionStorage.setItem('token', token);
          } else {
            sessionStorage.removeItem('token');
            const route = environment.usersURL + '#/login';
            window.location.href = route;
          }
        }),
        map((response) => {
          if (response) {
            if (this.tokenFromUrl != '') {
              const currentUrl = new URL(window.location.href);
              currentUrl.searchParams.delete('token');
              currentUrl.searchParams.delete('companyId');
              const urlWithoutToken = currentUrl.toString();
              history.replaceState({}, document.title, urlWithoutToken);
              this.router.navigate(['/panel']);
            }
            return true;
          } else {
            return false;
          }
        }),
        catchError((error) => {
          console.error('Error al verificar el token:', error);
          sessionStorage.removeItem('token');
          const route = environment.usersURL + '#/login';
          window.location.href = route;
          return of(false);
        })
      );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const tokenFromUrl = route.queryParamMap.get('token');
    this.tokenFromUrl = route.queryParamMap.get('token');
    const companyId = route.queryParamMap.get('companyId');

    if (companyId) {
      this.companyIdService.setCompanyId(companyId);
    }
    if (tokenFromUrl) {
      return this.validateAndRedirect(tokenFromUrl);
    } else {
      const storedToken = sessionStorage.getItem('token');
      if (
        storedToken !== null &&
        storedToken !== undefined &&
        !this.isTokenExpired(storedToken)
      ) {
        this.tokenFromUrl = '';
        return this.validateAndRedirect(storedToken);
      } else {
        const route = environment.usersURL + '#/login';
        window.location.href = route;
        return of(false);
      }
    }
  }

  /**
   * Comprobar caducidad del token actual que proviene de Users
   * @param token
   * @returns
   */
  private isTokenExpired(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      if (decoded.exp === undefined) {
        return false;
      }
      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);
      return date.valueOf() < new Date().valueOf();
    } catch (error) {
      return true;
    }
  }
}

export const isTokenGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  return inject(TokenGuard).canActivate(route, state);
};
