import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DualMaterialityPageRoutingModule } from './dual-materiality-routing.module';

import { DualMaterialityPage } from './dual-materiality.page';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { StepperModule } from 'primeng/stepper';
import { CardModule } from 'primeng/card';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ComponentsModule } from 'src/app/components/components.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    TagModule,
    ChipModule,
    OverlayPanelModule,
    ToastModule,
    TableModule,
    CardModule,
    DropdownModule,
    ChipModule,
    ReactiveFormsModule,
    InputTextareaModule,
    AccordionModule,
    ListboxModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    StepperModule,
    InputTextModule,
    FileUploadModule,
    IonicModule,
    ComponentsModule,
    DualMaterialityPageRoutingModule,
    TooltipModule,
  ],
  declarations: [DualMaterialityPage],
  providers: [ConfirmationService, MessageService],
})
export class DualMaterialityPageModule {}
